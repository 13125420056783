import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { ReactChild, ReactNode } from "react";
import { LegacyNavLink } from "../../components/LegacyNavLink";
import classes from "./NavigationButton.module.css";

interface NavigationButtonProps {
  disabled?: boolean;
  href: string;
  icon: ReactNode;
  text: ReactChild;
}

export const NavigationButton = (props: NavigationButtonProps) => {
  const { disabled, href, icon, text } = props;
  const expandedLayout = useMediaQuery("(min-width:1300px)");
  const activeClassName = classes.active;

  if (expandedLayout) {
    return (
      <Button
        disabled={disabled}
        className={classes.root}
        color="inherit"
        startIcon={icon}
        {...{
          activeClassName,
          component: LegacyNavLink as any,
          href,
        }}
      >
        {text}
      </Button>
    );
  } else {
    return (
      <Tooltip title={text}>
        <span>
          <IconButton
            disabled={disabled}
            className={classes.root}
            color="inherit"
            {...{
              activeClassName,
              component: LegacyNavLink as any,
              href,
            }}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
};
